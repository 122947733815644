import { SoftPageProps } from '@/layouts/web/types/SoftPageProps';
import { GenericObject } from '@/modules/core/data/types/GenericObject';
import DefaultErrorLayout from '@/modules/core/errorHandling/DefaultErrorLayout';
import { createLogger } from '@/modules/core/logging/logger';
import PreviewModeBanner from '@/modules/core/previewMode/components/PreviewModeBanner';
import ErrorPage from '@/pages/_error';
import * as Sentry from '@sentry/node';
import classnames from 'classnames';
import {
  NextRouter,
  useRouter,
} from 'next/router';
import React, {useEffect, useState} from 'react';

import WebHead, { HeadProps } from './WebHead';
import WebPageContainer from './WebPageContainer';
import WebPageHeader from '@/layouts/web/components/WebPageHeader';
import Annoucement from "@/components/centralbrisbanedental/Announcement";
import Topbar from "@/components/centralbrisbanedental/Topbar";
import NavBar from "@/components/centralbrisbanedental/NavBar/NavBar";
import Footer from "@/components/centralbrisbanedental/Footer/Footer";

const fileLabel = 'layouts/web/components/WebLayout';
const logger = createLogger({
  fileLabel,
});

export type Props = {
  /**
   * Content to display within the layout.
   *
   * Essentially, the page's content.
   */
  children: React.ReactNode;

  /**
   * Name of the layout.
   * Proposed: Type of shell
   *
   * isWrapper means there will be no additional child div between the main wrapper element and preceding section elements
   * hasWrapper means there will be an additional child div, used as a wrapper, around the preceding section elements.
   *
   * Will be used as CSS class for the main wrapper element.
   */
  layoutName?: 'public-layout' | 'demo-layout';
  mainShellType?: 'isWrapper' | 'hasWrapper'

  /**
   * Props forwarded to the Head component.
   *
   * Essentially, SEO metadata, etc.
   * Will use sane defaults if not specified.
   */
  headProps?: HeadProps;

  /**
   * Internal name of the page.
   *
   * Used by Sentry once, maybe this has other uses, it was previously used by Amplitude so perhaps useful for Analytics.
   * All events happening on the page could be linked to that page name if that wasn't a default thing.
   */
  pageName?: string;

  /**
   * Wrapper container for the page.
   *
   * By default, uses CorePageContainer component.
   */
  PageContainer?: React.FunctionComponent;

  /**
   * Force hiding the nav.
   */
  hideNav?: boolean;

  /**
   * Force hiding the footer.
   */
  hideFooter?: boolean;

  /**
   * Force hiding the preview banner.
   */
  hidePreviewBanner?: boolean;

  /**
   * Component to use as Head.
   *
   * @default BaseHead
   */
  Head?: React.FunctionComponent<HeadProps>;

  /**
   * Component to use as Footer.
   *
   * @default BaseFooter
   */
  Footer?: React.FunctionComponent;

  /**
   * Component to use as Nav.
   *
   * @default BaseNav
   */
  Nav?: React.FunctionComponent;
} & SoftPageProps;

/**
 * Handles the positioning of top-level elements within the page.
 *
 * It does the following:
 *  - Adds a Nav/Footer component, and the dynamic Next.js "Page" component in between.
 *  - Automatically track page views (Amplitude).
 *  - Handles errors by displaying the Error page, with the ability to contact technical support (which will send a Sentry User Feedback).
 *
 * XXX Core component, meant to be used by other layouts, shouldn't be used by other components directly.
 */
const WebLayout: React.FunctionComponent<Props> = (props): JSX.Element => {
  // console.log('corelayout');
  const {
    children,
    error,
    isInIframe = false, // Won't be defined server-side
    layoutName,
    headProps = {},
    pageSeoSettings,
    pageName,
    PageContainer = WebPageContainer,
    hideNav,
    hideFooter = true,
    hidePreviewBanner = true,
    Head = WebHead,
    // Footer = null,
    // Nav = null,
  } = props;
  logger.info('ReactDivWebMain headProps', headProps, pageSeoSettings);
  const [scrollY, setScrollY] = useState(0);

  Sentry.addBreadcrumb({ // See https://docs.sentry.io/enriching-error-data/breadcrumbs
    category: fileLabel,
    message: `Rendering ${fileLabel} for page ${pageName}`,
    level: 'debug',
  });
  Sentry.configureScope((scope): void => {
    scope.setTag('fileLabel', fileLabel);
  });

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    // NOTE Fragments Required to keep head, navigation, main, footer and other necessary layout structures seperated for semantic HTML
    // NOTE Web Layout Could Present Layouts ? Templates
    // NOTE THere may be a redundant component here but maybe thats useful
    <>
      {/* NOTE Content Model: HEAD Requires Page Level SEO Settings */}
      <Head {...pageSeoSettings} />

      {/* NOTE Content Model: Navigations Requires Site Navigation Settings and Data */}

      <div className="sticky top-0 z-10 shadow-md">
        <Annoucement
          // mobilePrimaryTextValue={`Our last working day of 2023 is Thursday 21 December for Christmas break and we reopen Monday 8 January 2024. Merry Christmas and Happy New Year!`}
          mobilePrimaryTextValue="Central Brisbane Dental is open and following COVID-19 safety standards."
          // desktopPrimaryTextValue={`Our last working day of 2023 is Thursday 21 December for Christmas break and we reopen Monday 8 January 2024. Merry Christmas and Happy New Year!`}
          desktopPrimaryTextValue="Central Brisbane Dental is open and following COVID-19 safety standards."
          tailwindWrapperClassNames={{
            tailwind: "bg-[#400068]"
          }}
          tailwindContainerClassNames={{
            tailwind: "mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8 flex flex-wrap items-center justify-between"
          }}
        />
        <Topbar
          scrollY={scrollY}
          style={{
            background: "bg-[#f3f3f3]"
          }}
        />
      </div>
      <NavBar />

      {/* NOTE Content Model: Navigations Requires Site Navigation Settings and Data */}
      <main
        id="main"
        role={'main'}
        // className={classnames('bg-[#0D022E]', layoutName)}
      >

        {/* Loaded from components/Head - See https://github.com/mikemaccana/outdated-browser-rework */}
        {/*<div*/}
        {/*  id="outdated"*/}
        {/*  style={{ display: 'none' }}*/}
        {/*></div>*/}

        <>
          {
            // If an error happened, we display it instead of displaying the page
            // We display a custom error instead of the native Next.js error by providing children (removing children will display the native Next.js error)
            error ? (
              // @ts-ignore
              <ErrorPage
                statusCode={500}
                isReadyToRender={true}
                err={error}
              >
                <DefaultErrorLayout
                  error={error}
                />
              </ErrorPage>
            ) : (
              // @ts-ignore
              <>
                {children && children}
              </>
            )
          }
        </>

      </main>

      {/* NOTE Content Model: Footer Requires Site Footer Settings and Data */}
      <Footer />

    </>
  );
};

export default WebLayout;
