import { FC } from 'react';
import NextHead from 'next/head';
import {
  NRN_DEFAULT_FONT,
} from '@/app/constants';
import {
  AllowedVariableFont,
  fontsBasePath,
  VariableFontConfig,
  variableFontsConfig,
} from '@/modules/core/fonts/fonts';

export type HeadProps = {
	/**
	 * Title of the page. (SEO)
	 *
	 * Displayed in the browser tab.
	 */
	seoTitle?: string;

	/**
	 * Description of the page. (SEO)
	 *
	 * Used as Open Graph and twitter description.
	 */
	seoDescription?: string;

	/**
	 * Url of the page. (SEO)
	 *
	 * Used as Open Graph url.
	 */
	seoUrl?: string;

	/**
	 * Image associated with the page. (SEO)
	 *
	 * Used as Open Graph and twitter image.
	 */
	seoImage?: string;

	/**
	 * Favicon.
	 *
	 * Websites usually use the same favicon for all their pages.
	 */
	favicon?: string;

	/**
	 * Additional links and scripts HTML elements.
	 *
	 * Can be used to load 3rd party scripts and such.
	 * It is recommended to use a "<Fragment>" as wrapper.
	 */
	additionalContent?: React.ReactElement;
}

/**
 * Custom Next.js Head component.
 *
 * Configures SEO, load fonts.
 *
 * TODO SEO should be done differently. See https://github.com/UnlyEd/next-right-now/issues/150
 *
 * XXX Core component, meant to be used by other layouts, shouldn't be used by other components directly.
 *
 * https://github.com/vercel/next.js#populating-head
 */
const WebHead: FC<HeadProps> = (props): JSX.Element => {
  console.log('WebHead props', props);
  // const customer: Customer = useCustomer();
  const defaultDescription = 'Flexible production-grade boilerplate with Next.js 11, Vercel and TypeScript. Includes multiple opt-in presets using Storybook, Airtable, Analytics, CSS-in-JS, Monitoring, End-to-end testing, Internationalization, CI/CD and SaaS B2B multiple single-tenants (monorepo) support';
  const defaultMetaURL = 'https://github.com/UnlyEd/next-right-now';
  // const defaultMetaImage = customer?.theme?.logo?.url;
  const defaultMetaImage = 'placeholder';
  const defaultFavicon = '/favicon.ico';

  const {
    seoTitle = 'SEO Title Placeholder',
    seoDescription = defaultDescription,
    seoImage = defaultMetaImage,
    seoUrl = defaultMetaURL,
    favicon = defaultFavicon,
    additionalContent = null,
  } = props;
  // const fontName: AllowedVariableFont = customer?.theme?.fonts || NRN_DEFAULT_FONT;
  // TODO
  // Make this use the API
  const fontName: AllowedVariableFont = 'Nunito';
  const config: VariableFontConfig = variableFontsConfig.find((config) => config?.fontName === fontName);
  const format = config?.format || 'woff2';
  const fontFile = config?.fontFile || `${fontName.toLowerCase()}-v25-latin-regular.${format}`;

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{`${seoTitle} | Central Brisbane Dental`}</title>
      <meta
        name="description"
        content={seoDescription}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      <link
        rel="icon"
        href={favicon}
      />

      {/* Preload the font */}
      <link
        rel="preload"
        href={`${fontsBasePath}/${fontName}/${fontFile}`}
        as="font"
        type={`font/${format}`}
        crossOrigin="anonymous"
      />

      <meta property="og:url" content={seoUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta
        property="og:description"
        content={seoDescription}
      />
      <meta name="twitter:site" content={seoUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={seoImage} />
      <meta property="og:image" content={seoImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Detect outdated browser and display a popup about how to upgrade to a more recent browser/version */}
      {/* XXX See public/static/libs/detect-outdated-browser/README.md */}
      {/*
        XXX DISABLED because of https://github.com/mikemaccana/outdated-browser-rework/issues/57#issuecomment-620532590
          TLDR; Display false-positive warnings on embedded browsers (on mobile devices) if they're too old and the user can't do anything about it (e.g: Facebook Chrome, Linkedin Chrome, etc.)
      */}
      {/*<script async={true} src="/assets/libs/outdated-browser-rework/outdated-browser-rework.min.js" />*/}
      {/*<link rel="stylesheet" href="/assets/libs/outdated-browser-rework/outdated-browser-rework.css" />*/}

      {
        additionalContent && (
          additionalContent
        )
      }

    </NextHead>
  );
};

export default WebHead;
