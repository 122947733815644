import * as Sentry from '@sentry/node';
import { FC } from 'react';
import { JSX } from 'preact';
import { Button } from 'reactstrap';
import { GenericObject } from '../data/types/GenericObject';
import ErrorDebug from './ErrorDebug';

type Props = {
  error: Error;
  context?: GenericObject;
}

/**
 * Default error layout, used by DefaultLayout to display errors instead of the page's content, when an error is caught
 *
 * Displays a report dialog modal allowing end-users to provide a manual feedback about what happened.
 * You may want to customise this component to display different error messages to the end users, based on statusCode or other information.
 */
const DefaultErrorLayout: FC<Props> = (props): JSX.Element => {
  const {
    error,
    context,
  } = props;
  const errorEventId = Sentry.captureException(error);

  return (
    <div
      className="text-center mx-20"
    >
      <div
        className="mx-16"
      >
        <h1>Service currently unavailable</h1>
        <pre>Error 500.</pre>
      </div>

      <div>
        <p>
          Try to refresh the page. Please contact our support below if the issue persists.
        </p>
        <Button
          color={'primary'}
          onClick={(): void =>
            // @ts-ignore XXX showReportDialog is not recognised by TS (due to the webpack trick that replaces @sentry/node), but it works just fine
            Sentry.showReportDialog({ eventId: errorEventId })
          }
        >
          Contact support
        </Button>
      </div>

      {
        process.env.NEXT_PUBLIC_APP_STAGE !== 'production' && (
          <ErrorDebug
            error={error}
            context={context}
          />
        )
      }
    </div>
  );
};

export default DefaultErrorLayout;
